/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.component.form-thanks {
  max-width: 720px;
  margin: 10em auto;
  overflow: hidden;
}

@media (max-width: 767px) {
  .component.form-thanks {
    padding-top: 7em;
  }
}

.component.form-thanks p {
  font-size: 1em;
}

.component.form-thanks p.p-large {
  margin-top: 2em;
  color: #77787c;
  font-size: 1.5em;
  font-weight: 500;
}

.component.form-thanks .social-links {
  display: block;
  max-width: 154px;
  margin: 0 auto;
  padding-right: 0;
  font-size: 2em;
}

.component.form-thanks .social-links a {
  font-size: 1.5em;
}

.component.form-thanks .social-links .col-3 {
  flex: 30%;
  max-width: 35%;
}

.component.form-thanks .social-links .row {
  margin: 0 auto;
}

.component.form-thanks .event-list {
  margin-top: 3em;
  padding-top: 3em;
  font-size: 1.3em;
  border-top: 1px solid #d2d2d2;
}

.component.form-thanks .event-list h3 {
  margin-bottom: 1em;
  font-size: 1.3em;
  text-align: center;
}

.form {
  font-size: 2em;
  overflow: hidden;
  margin: auto;
}

.form form {
  overflow: hidden;
  margin-bottom: 10em;
}

@media (max-width: 767px) {
  .form .form-container {
    font-size: 0.8em;
  }
}

.form table {
  width: 100%;
}

.form .scfSectionContent > div {
  margin: 5px 0 0 0;
  padding: 0;
}

.form .scfSectionContent > div ~ div {
  margin: 0;
}

.form .scfSectionContent > div > label,
.form .scfSectionContent > div > div {
  width: 100%;
  display: block;
  padding: 0;
}

.form .scfRequired {
  display: none !important;
}

.form fieldset {
  border: 1px solid #d2d2d2;
  padding: 15px;
  margin: 10px 0;
  position: relative;
}

.form fieldset legend {
  font-weight: 700;
  padding: 0 5px;
  font-size: 16px;
  position: absolute;
  top: -33px;
  background: #fff;
  left: 0;
}

.form .scfSectionContent > div {
  box-sizing: border-box;
  margin: 15px 0;
  border: 1px dashed gray;
  padding: 10px;
  position: relative;
}

.form .scfSectionContent > div:after {
  clear: both;
  content: "";
  display: block;
}

.form .scfSectionContent > div:hover [class$="GeneralPanel"] > [class$="UsefulInfo"] {
  opacity: 1;
}

.form .scfSectionContent > div select,
.form .scfSectionContent > div input {
  margin-left: 5px;
}

.form .scfSectionContent [class$="UsefulInfo"] {
  transition: opacity 0.75s;
  box-sizing: border-box;
  opacity: 0;
  font-size: 12px;
  margin-left: 5px;
  position: absolute;
  border: 1px solid #d2d2d2;
  left: 0px;
  top: -15px;
  padding: 4px 8px;
  max-width: 98%;
  width: auto;
  background-color: #F5F5F5;
  color: #808080;
}

.form input,
.form select,
.form textarea {
  border: 1px solid #d2d2d2;
  padding: 2px 4px;
  max-width: 98%;
}

@media (max-width: 991px) {
  .form input,
  .form select,
  .form textarea {
    font-size: 16px;
  }
}

.form .scfCaptcha input[type="text"] {
  margin: 0;
}

.form .scfCaptchaLimitGeneralPanel,
.form .scfCaptchStrongTextPanel {
  max-width: 100%;
  width: auto;
}

.form .scfCaptchaLabel {
  display: none;
}

.form .scfSectionContent .scfCaptchaGeneralPanel embed {
  max-width: 180px;
}

.form input[type="image"] {
  border: none;
}

.form .form-submit-border.form-group {
  display: inline-block;
  width: auto;
  padding: 0;
  margin-left: 0;
  margin-top: 2.9em;
}

@media (max-width: 767px) {
  .form .form-submit-border.form-group {
    text-align: center;
    width: 100%;
  }
}

.form .form-submit-border.form-group:after {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 17px;
  width: 1.5em;
  height: 2px;
  transform: translateY(-50%);
  transition: transform 0.2s ease;
  background-color: #fff;
  content: '';
  will-change: transform;
}

@media (max-width: 767px) {
  .form .form-submit-border.form-group:after {
    right: 44%;
  }
}

.form .form-submit-border.form-group:hover:after {
  transform: translateX(-6px) translateY(-50%);
}

.form input[type="submit"],
.form button {
  border: 0;
  background: #000;
  cursor: pointer;
  font-size: 1em;
  color: #fff;
  padding: 0.8em 3.5em 0.9em 1em;
}

@media (max-width: 767px) {
  .form input[type="submit"],
  .form button {
    width: 40%;
  }
}

.form input[type="text"],
.form input[type="password"],
.form textarea,
.form input[type="file"] {
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  border-bottom: 1px solid #d2d2d2;
  width: 95%;
  padding: 2px;
}

.form input[type="text"] + span,
.form input[type="password"] + span,
.form textarea + span,
.form input[type="file"] + span {
  display: inline-block;
  vertical-align: middle;
}

.form input[type="file"] {
  appearance: none;
}

.form input[type="radio"] {
  position: absolute;
}

.form input[type="radio"] + label:before {
  content: "";
  position: absolute;
  top: 2px;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #FFFFFF;
}

.form input[type="radio"] + label:after {
  content: "";
  position: absolute;
  top: 2px;
  display: block;
  width: 16px;
  height: 15px;
  background: image-url("radiobox.png");
}

.form input[type="radio"]:checked + label:after {
  background-position: -16px 0;
}

.form input[type="radio"] + label {
  user-select: none;
  padding-left: 20px;
  margin-left: 5px;
  position: relative;
}

.form select {
  border: 1px solid #d2d2d2;
  max-height: 35px;
  position: relative;
  text-indent: 2px;
}

.form .scfListBox {
  background: none;
  max-height: none;
}

.form .scfDateSelectorDay,
.form .scfDateSelectorMonth,
.form .scfDateSelectorYear {
  width: 30%;
}

.form .form-group {
  float: left;
  clear: none;
  width: 100%;
  position: relative;
}

.form .form-group select,
.form .form-group .form-control {
  border: 0;
  color: #55565b;
  border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  width: 100%;
  -webkit-appearance: none;
  border-radius: 0;
  background-color: transparent;
  margin-bottom: 4.2em;
}

.form .form-group select:hover,
.form .form-group .form-control:hover {
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}

.form .form-group select {
  background-image: url(../images/icons/icon-nav-arrow.svg);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 1em;
}

.form .form-group textarea {
  height: 4.8em;
}

.form .form-group .checkbox {
  margin-bottom: 1.3em;
}

.form .form-group.has-error select,
.form .form-group.has-error .form-control {
  border-bottom: 1px solid #ee2737;
}

.form .required-field .control-label:before {
  display: none;
}

.form .required-field .control-label:after {
  color: #ee2737;
  margin-left: 0.5em;
  content: "*";
}

.form .help-block.field-validation-error {
  color: #ee2737;
  position: absolute;
  top: 31%;
  left: 0;
  font-size: 0.7em;
}

.form .half-control {
  width: 48%;
  margin-right: 2%;
}

@media (max-width: 767px) {
  .form .half-control {
    width: 100%;
  }
}

.form .has-error.has-feedback:not(.form-group) {
  margin-top: 1em;
  margin-bottom: 1.4em;
  padding: 1px;
}

.form .has-error.has-feedback:not(.form-group) .list-group li {
  display: none;
}

.form .form-control {
  position: relative;
}

.form .control-label {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  text-align: left;
  transition: all .2s ease-out;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
  color: rgba(0, 0, 0, 0.6);
}

.form .field--not-empty .control-label {
  transform: translateY(-100%);
  font-size: 0.8em;
}

.form .form-control {
  transition: all .2s ease-out;
  width: 100%;
}

.form .form-control:hover, .form .form-control:focus {
  outline: 0;
}

.field--not-empty .form .form-control {
  padding-bottom: 2px;
  padding-top: 18px;
}

.form .customCheckbox {
  float: left;
  position: relative;
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid #bbbcbc;
  overflow: hidden;
  margin: 0 0.7em 0.7em 0;
}

.form .customCheckbox.customCheckboxChecked {
  border: 1px solid #000000;
}

.form .customCheckbox input {
  opacity: 0;
  cursor: pointer;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.form .customCheckbox span {
  display: none;
  text-align: center;
  line-height: 20px;
  font-size: 90%;
  color: #222;
}

.form .customCheckbox.customCheckboxChecked span {
  display: block;
  font-family: arial, Segoe UI Symbol;
}

.form .has-error {
  color: #ee2737;
}

.form form > form .form-group .control-label {
  transform: translateY(-100%);
  font-size: 0.8em;
}

.form form > form .form-submit-border {
  position: relative;
}

.form form > form .form-submit-border:before {
  color: #ee2737;
  content: "Error. Please see above for more details.";
  position: absolute;
  top: -50px;
}

@media (max-width: 767px) {
  .form form > form .form-submit-border:before {
    top: -20px;
  }
}

.form form > form .has-error.form-group {
  padding-bottom: 15px;
}

.form.hints-below .scfSectionContent [class$="UsefulInfo"] {
  position: static;
  opacity: 1;
  max-width: 100%;
  width: 100%;
  margin: 5px 0 0 5px;
}

.form.labels-above label {
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin: 0;
}

.form.labels-above .scfEmailGeneralPanel, .form.labels-above .scfMultipleLineGeneralPanel, .form.labels-above .scfSingleLineGeneralPanel, .form.labels-above .scfPasswordGeneralPanel, .form.labels-above .scfNumberGeneralPanel, .form.labels-above .scfDatePickerGeneralPanel, .form.labels-above .scfListBoxGeneralPanel, .form.labels-above .scfDropListGeneralPanel, .form.labels-above .scfDateGeneralPanel, .form.labels-above .scfRadioButtonListGeneralPanel, .form.labels-above .scfCheckBoxListGeneralPanel, .form.labels-above .scfFileUploadGeneralPanel, .form.labels-above .scfDateSelectorGeneralPanel, .form.labels-above .scfCreditCardGeneralPanel, .form.labels-above .scfConfirmPasswordGeneralPanel, .form.labels-above .scfCaptchaGeneralPanel, .form.labels-above .scfTelephoneGeneralPanel, .form.labels-above .scfSmsTelephoneGeneralPanel {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  width: 93%;
}

.form.labels-above .scfSectionContent .scfRequired {
  margin: 0 5px;
}

.form.labels-above .scfMultipleLineGeneralPanel {
  width: 95%;
}

.form.labels-above .scfMultipleLineTextBox {
  margin: 0 0 0 5px;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
  min-width: 10em;
  font-size: 2em;
  padding: 1rem 2rem 2rem;
  border: 1px solid #dbdbdb;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background: white;
  margin-top: 1em;
}

.select2-dropdown:before {
  display: block;
  position: absolute;
  top: -11px;
  left: 15px;
  width: 20px;
  height: 20px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 1px solid #dbdbdb;
  border-right: 0;
  border-bottom: 0;
  background: #fff;
  content: "";
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px 0;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: -8px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image: url(../images/icons/icon-arrow--black.svg);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  height: 0.8em;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 1.4em;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

@media (max-width: 991px) {
  .boxed.container.indent-top {
    padding-top: 3em;
  }
}
